<template>
    <div>
        <form>
            <div class="text-2xl uppercase mb-4 font-medium">Login</div>

            <div class="p-field mb-4">
                <label class="block text-600 mb-2">
                    {{ $t('E-Mail-Address') }}
                </label>
                <InputText
                    class="w-full"
                    type="email"
                    required
                    v-model="email"
                />
                <small class="p-invalid block mt-2" v-if="error === true">
                    {{ $t('No user with this credentials found') }}
                </small>
            </div>

            <template v-if="!showPassword">
                <Button
                    :label="$t('Send authentication code')"
                    class="w-full mb-2"
                    :class="{'cursor-pointer': !$v.email.$invalid}"
                    @click="sendChallenge"
                    :disabled="$v.email.$invalid"
                />

                <Button
                    :label="$t('I already have an authentication code.')"
                    class="w-full p-button-text cursor-pointer"
                    @click="showPassword = true;"
                />
            </template>

            <template v-if="showPassword">
                <div class="p-field mb-4">
                    <label class="block text-600 mb-2">
                        {{ $t('Code') }}
                    </label>
                    <InputText
                        class="w-full"
                        type="text"
                        required
                        v-model="challenge"
                    />
                </div>

                <div class="p-field mb-4">
                    <label class="block text-600 mb-2">
                        {{ $t('Password') }}
                    </label>
                    <InputText
                        class="w-full"
                        type="password"
                        required
                        v-model="password"
                    />
                </div>

                <Button label="Login" class="w-full mb-2" @click="login()"/>

                <Button
                    :label="$t('Back')"
                    class="w-full p-button-text cursor-pointer"
                    @click="showPassword = false;"
                />
            </template>
        </form>
    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';
    import auth from './../../plugins/auth';
    import {required, email} from 'vuelidate/src/validators';

    export default {
        validations: {
            email: {
                required: required,
                email: email,
            }
        },
        data() {
            return {
                email: '',
                password: '',
                challenge: '',
                error: null,
                showPassword: false,
            }
        },
        components: {
            Button,
            InputText,
        },
        methods: {
            sendChallenge() {
                this.$http.post('challenges', {email: this.email}).then((response) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('Success'),
                        detail: this.$t('You will receive an authentication code shortly. This is valid for 5 minutes.'),
                        life: 3000
                    });
                    this.showPassword = true;
                }, () => {
                    this.$toast.add({
                        severity: 'alert',
                        summary: this.$t('Error'),
                        detail: this.$t('Unfortunately, no authentication code could be sent to you. This happens when there is no matching user with the specified email or a code has already been requested recently.'),
                        life: 3000
                    });
                });
            },
            login() {
                this.error = false;
                this.formErrors = {};
                this.$store.commit('incrementLoadingIndex');
                this.$http.post('auth', {
                    email: this.email,
                    password: this.password,
                    challenge: this.challenge
                }).then(response => {
                    auth.setAuth(response.data.token);
                    auth.setRefreshToken(response.data.refresh_token);
                    this.$store.commit('decrementLoadingIndex');
                    this.$router.push('/surveys');
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('Success'),
                        detail: this.$t('You have successfully logged in.'),
                        life: 3000
                    });
                }, () => {
                    this.error = true;
                    this.$store.commit('decrementLoadingIndex');
                    this.formErrors = {
                        email: [
                            this.$t('No user found or password incorrect'),
                        ]
                    };
                });
            },
        }
    }
</script>
